
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { Money } from 'v-money';

  let timeout: any = null;

  @Component({
    components: { Money }
  })
  export default class HipsterInput extends Vue {
    @Prop() value: any;
    @Prop() placeholder: string;
    @Prop({ default: 'text' }) type?: string;
    @Prop() max?: number;
    @Prop({ default: 0 }) min?: number;
    @Prop({ default: 0 }) timeout?: number;
    @Prop({ default: () => ({
        decimal: '.',
        thousands: ',',
        prefix: '$',
        suffix: '',
        precision: 2,
        masked: false
    }) }) moneyConfig?: object;

    onChanged(eventName: string, value: any) {
      if (this.timeout) {
        clearTimeout(timeout);

        timeout = setTimeout(() => this.$emit(eventName, value), 500);
      }

      this.$emit(eventName, value);
    }
  }
