
  import { Component, Vue } from 'vue-property-decorator';
  import { SportNotStrict } from '@/types/entities';
  import { UPDATE_LEAGUES } from '@/store/leagues';
  import HipsterCheckbox from '@/components/HipsterCheckbox.vue';

  @Component({
    components: { HipsterCheckbox }
  })
  export default class LeaguesListTable extends Vue {
    public updateSport(id: number, params: SportNotStrict) {
      this.$store.dispatch(UPDATE_LEAGUES, { id, params });
    }
  }
