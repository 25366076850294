
  import { Component, Prop, Vue } from 'vue-property-decorator';

  @Component
  export default class HipsterCheckbox extends Vue {
    @Prop() value: boolean | number;
    @Prop({ default: '' }) title?: string;
    @Prop({ default: false }) disabled: boolean;

    toggle() {
      if (!this.disabled) {
        this.$emit('input', !this.value);
      }
    }
  }
