
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
  import { clone, get, isEmpty, findIndex } from 'lodash';

  let timeout: any = null;

  declare type SelectorValue = object | object[];

  @Component
  export default class HipsterSelector extends Vue {
    @Prop({ default: [] }) items: any[];
    @Prop({ default: '' }) allItemsLabel?: string;
    @Prop({ default: '' }) placeholder: string;
    @Prop({ default: false }) multiSelect?: boolean;
    @Prop({ default: false }) externalFiltering: boolean;
    @Prop({ default: 'name' }) labelKey: string;
    @Prop() value: SelectorValue;

    public query: string = '';
    public filteredResults: any[] = [];

    mounted() {
      if (!isEmpty(this.value)) {
        this.query = get(this.value, this.labelKey);
      }

      this.filteredResults = [...this.items];
    }

    get placeholderWithData() {
      if (!this.multiSelect || !(this.value as object[]).length) {
        return this.placeholder;
      }

      const values = (this.value as object[])
        .map((item) => get(item, this.labelKey))
        .join(', ');

      return `${this.placeholder} (${values})`;
    }

    @Watch('items')
    onItemsChanged(val: any[], oldVal: any[]) {
      this.filteredResults = val;
    }

    @Watch('value')
    onValueChanged(val: SelectorValue, oldVal: SelectorValue) {
      this.query = get(val, this.labelKey);
    }

    public select(item: SelectorValue) {
      this.query = (item) ? this.getDropdownLabel(item) : '';

      if (!this.multiSelect) {
        this.$emit('input', item);

        return;
      }

      const index = findIndex(
        (this.value as object[]),
        (selectedItem: object) => get(selectedItem, this.labelKey) === get(item, this.labelKey)
      );

      const value = clone(this.value);

      if (index === -1) {
        (value as object[]).push(item);
      } else {
        (value as object[]).splice(index, 1);
      }

      this.$emit('input', value);
    }

    public filter(query: string) {
      if (this.externalFiltering) {
        clearTimeout(timeout);

        timeout = setTimeout(() => this.$emit('search', query), 500);
      } else {
        this.simpleFiltering(query);
      }
    }

    private simpleFiltering(query: string) {
      this.filteredResults = this.items.filter((item) => {
        if (isEmpty(query)) {
          return true;
        }

        const filter = query.toLowerCase();

        const name = get(item, this.labelKey).toLowerCase();

        return name.indexOf(filter) !== -1;
      });
    }

    private getDropdownLabel(item: object) {
      return get(item, this.labelKey, '').replace(/<(?:.|\\n)*?>/gm, '');
    }

    private contains(expectedItem: object) {
      if (!this.multiSelect) {
        return false;
      }

      return (this.value as object[]).some(
        (item: object) => get(item, this.labelKey) === get(expectedItem, this.labelKey)
      );
    }
  }
