
  import { Component, Vue } from 'vue-property-decorator';

  import HipsterInput from '@/components/HipsterInput.vue';
  import HipsterSelector from '@/components/HipsterSelector.vue';
  import LeaguesListTable from '@/components/LeaguesListTable.vue';

  import { SearchLeaguesParams } from '@/types/search';
  import { FETCH_LEAGUES } from '@/store/leagues';
  import { FETCH_SPORTS } from '@/store/sports';
  import { RawLocation } from 'vue-router';

  @Component({
    components: { HipsterInput, HipsterSelector, LeaguesListTable }
  })
  export default class LeaguesList extends Vue {
    public filters: SearchLeaguesParams = {
      per_page: 15,
      page: 1,
      query: '',
      is_supported: null,
      with: ['sport'],
      sport_id: null
    };

    public statuses = [
      { name: 'No', value: 0 },
      { name: 'Yes', value: 1 }
    ];

    public selectedStatus = this.statuses[0];
    public selectedSport = null;

    created() {
      this.search(this.$route.query as SearchLeaguesParams);
      this.$store.dispatch(FETCH_SPORTS, {all: 1});
    }

    get sports() {
      return [ {name: this.$t('leagues.allSports'), id: null} ]
        .concat(this.$store.state.sports.sportsList.data);
    }

    public search(filter: SearchLeaguesParams) {
      if (!filter.page) {
        filter.page = 1;
      }

      this.filters = Object.assign(this.filters, this.$route.query, filter);

      if (this.filters.with as any instanceof String) {
        this.filters.with = [this.filters.with as any as string];
      }

      this.$router.push({ query: this.filters } as RawLocation);

      this.$store.dispatch(FETCH_LEAGUES, this.filters);
    }
  }
